
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as reviewTW2pVHaqKuMeta } from "/vercel/path0/pages/admin/checklists/review.vue?macro=true";
import { default as dashboardYi2jyZZMHDMeta } from "/vercel/path0/pages/admin/dashboard.vue?macro=true";
import { default as testuxNnS6TxcPMeta } from "/vercel/path0/pages/admin/lms/test.vue?macro=true";
import { default as indexHSIYyOmJPsMeta } from "/vercel/path0/pages/articles/index.vue?macro=true";
import { default as _91articleId_93jynFiE1eWBMeta } from "/vercel/path0/pages/articles/view/[articleId].vue?macro=true";
import { default as activate_45accountTvFTg4uzk1Meta } from "/vercel/path0/pages/auth/activate-account.vue?macro=true";
import { default as demo_45login2C58Y06W6eMeta } from "/vercel/path0/pages/auth/demo-login.vue?macro=true";
import { default as errorKJirDpNItLMeta } from "/vercel/path0/pages/auth/error.vue?macro=true";
import { default as forgot_45passwordRoBld5gkuaMeta } from "/vercel/path0/pages/auth/forgot-password.vue?macro=true";
import { default as Login716SBvoAAxMeta } from "/vercel/path0/pages/auth/Login.vue?macro=true";
import { default as oauthw6yJrc4EM9Meta } from "/vercel/path0/pages/auth/oauth.vue?macro=true";
import { default as RegisterD36lJ5bfN2Meta } from "/vercel/path0/pages/auth/Register.vue?macro=true";
import { default as reset_45passwordNF4wjmDL6XMeta } from "/vercel/path0/pages/auth/reset-password.vue?macro=true";
import { default as _91assignmentId_93x0TbIE69gEMeta } from "/vercel/path0/pages/classrooms/[classroomId]/assignments/[assignmentId].vue?macro=true";
import { default as assignmentsGXY1TwAHS3Meta } from "/vercel/path0/pages/classrooms/[classroomId]/assignments.vue?macro=true";
import { default as _91classSessionId_93oesva55381Meta } from "/vercel/path0/pages/classrooms/[classroomId]/class-sessions/[classSessionId].vue?macro=true";
import { default as class_45sessionsk2OeP7b8ocMeta } from "/vercel/path0/pages/classrooms/[classroomId]/class-sessions.vue?macro=true";
import { default as addmrLmnHCzU8Meta } from "/vercel/path0/pages/classrooms/[classroomId]/emails/add.vue?macro=true";
import { default as _91emailId_9350nhuLfzUJMeta } from "/vercel/path0/pages/classrooms/[classroomId]/emails/edit/[emailId].vue?macro=true";
import { default as indexLAj9lbEH3PMeta } from "/vercel/path0/pages/classrooms/[classroomId]/emails/index.vue?macro=true";
import { default as indexltXDuCsqipMeta } from "/vercel/path0/pages/classrooms/[classroomId]/gradebook/index.vue?macro=true";
import { default as _91studentId_93A40VYk7t6YMeta } from "/vercel/path0/pages/classrooms/[classroomId]/report-card/[studentId].vue?macro=true";
import { default as indexmIrw6lo9kPMeta } from "/vercel/path0/pages/classrooms/[classroomId]/resources/index.vue?macro=true";
import { default as _91studentId_93NipGKrntpNMeta } from "/vercel/path0/pages/classrooms/[classroomId]/students/[studentId].vue?macro=true";
import { default as studentsHv9T2ApiaFMeta } from "/vercel/path0/pages/classrooms/[classroomId]/students.vue?macro=true";
import { default as indextcnJMHgIQsMeta } from "/vercel/path0/pages/classrooms/[classroomId]/style/index.vue?macro=true";
import { default as _91classroomId_93vyhfg9rdTvMeta } from "/vercel/path0/pages/classrooms/[classroomId].vue?macro=true";
import { default as editExUaEm6z8OMeta } from "/vercel/path0/pages/classrooms/emails/[id]/edit.vue?macro=true";
import { default as addHJS66JjvSwMeta } from "/vercel/path0/pages/classrooms/emails/add.vue?macro=true";
import { default as indexA4Qwm6PMCDMeta } from "/vercel/path0/pages/classrooms/emails/index.vue?macro=true";
import { default as addw0JPeMCrVTMeta } from "/vercel/path0/pages/configure/classrooms/add.vue?macro=true";
import { default as edite89g2exz5uMeta } from "/vercel/path0/pages/configure/comments/[id]/edit.vue?macro=true";
import { default as addD5aYXRa6ARMeta } from "/vercel/path0/pages/configure/comments/add.vue?macro=true";
import { default as addfnyrPWXnFaMeta } from "/vercel/path0/pages/configure/comments/bulk/add.vue?macro=true";
import { default as importvwD2tL0BWlMeta } from "/vercel/path0/pages/configure/comments/bulk/import.vue?macro=true";
import { default as index24bk44ADpnMeta } from "/vercel/path0/pages/configure/comments/index.vue?macro=true";
import { default as curriculumal1D3It1UzMeta } from "/vercel/path0/pages/configure/curriculum.vue?macro=true";
import { default as wordsW188IV4EzxMeta } from "/vercel/path0/pages/configure/dictionary/words.vue?macro=true";
import { default as assignmentsW9OEBL8xgEMeta } from "/vercel/path0/pages/configure/lms/assignments.vue?macro=true";
import { default as choosex593Mk9uekMeta } from "/vercel/path0/pages/configure/lms/choose.vue?macro=true";
import { default as classroomsQBVTjMZjMOMeta } from "/vercel/path0/pages/configure/lms/classrooms.vue?macro=true";
import { default as finalizekwDQA19yumMeta } from "/vercel/path0/pages/configure/lms/finalize.vue?macro=true";
import { default as submissionsU3JwXi0RCzMeta } from "/vercel/path0/pages/configure/lms/submissions.vue?macro=true";
import { default as _91organizationId_93gtvSG4BoPVMeta } from "/vercel/path0/pages/configure/organizations/edit/[organizationId].vue?macro=true";
import { default as index8NHHtGKS2CMeta } from "/vercel/path0/pages/configure/organizations/index.vue?macro=true";
import { default as password4LtnzSffadMeta } from "/vercel/path0/pages/configure/password.vue?macro=true";
import { default as indexspXjquMM6fMeta } from "/vercel/path0/pages/configure/profile/orders/index.vue?macro=true";
import { default as payment_45methods19vQ7r3TGiMeta } from "/vercel/path0/pages/configure/profile/payment-methods.vue?macro=true";
import { default as quick_45keysXq2kp9dEwkMeta } from "/vercel/path0/pages/configure/quick-keys.vue?macro=true";
import { default as subscriptioniugGyYh2wCMeta } from "/vercel/path0/pages/configure/subscription.vue?macro=true";
import { default as converttpivZPv8a0Meta } from "/vercel/path0/pages/convert.vue?macro=true";
import { default as preview48I50uZSPnMeta } from "/vercel/path0/pages/document/[documentId]/[revisionId]/checklist/preview.vue?macro=true";
import { default as _91documentId_93XOYQWNruB2Meta } from "/vercel/path0/pages/documents/[mode]/review/[documentId].vue?macro=true";
import { default as submittedigt0FHwdNHMeta } from "/vercel/path0/pages/documents/[mode]/submitted.vue?macro=true";
import { default as message6SqUgVLFEZMeta } from "/vercel/path0/pages/errors/message.vue?macro=true";
import { default as homeaAKJ71q3qIMeta } from "/vercel/path0/pages/home.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91id_934fWRiA842XMeta } from "/vercel/path0/pages/level/documents/[id].vue?macro=true";
import { default as lmsWlIhPqTusaMeta } from "/vercel/path0/pages/lms.vue?macro=true";
import { default as add7xh7tITbJuMeta } from "/vercel/path0/pages/manage/annotation/types/add.vue?macro=true";
import { default as _91id_93BOgu1adRobMeta } from "/vercel/path0/pages/manage/annotation/types/edit/[id].vue?macro=true";
import { default as indexaE4rEjLhVHMeta } from "/vercel/path0/pages/manage/annotation/types/index.vue?macro=true";
import { default as add0to5VaVcSgMeta } from "/vercel/path0/pages/manage/announcements/add.vue?macro=true";
import { default as _91id_93SRr64J7d2OMeta } from "/vercel/path0/pages/manage/announcements/edit/[id].vue?macro=true";
import { default as indexoTzbXJSpIlMeta } from "/vercel/path0/pages/manage/announcements/index.vue?macro=true";
import { default as addR25HqEBHH4Meta } from "/vercel/path0/pages/manage/articles/add.vue?macro=true";
import { default as _91id_93bhKSpAuvw6Meta } from "/vercel/path0/pages/manage/articles/edit/[id].vue?macro=true";
import { default as indexgskJXvA9EZMeta } from "/vercel/path0/pages/manage/articles/index.vue?macro=true";
import { default as addNn4UQp9ddpMeta } from "/vercel/path0/pages/manage/capabilities/add.vue?macro=true";
import { default as _91version_93ilDS13nyWPMeta } from "/vercel/path0/pages/manage/capabilities/edit/[version].vue?macro=true";
import { default as indexHWijtWCqqgMeta } from "/vercel/path0/pages/manage/capabilities/index.vue?macro=true";
import { default as addkFSdZnvL1zMeta } from "/vercel/path0/pages/manage/checklists/add.vue?macro=true";
import { default as _91id_935Kc2gxS0MQMeta } from "/vercel/path0/pages/manage/checklists/edit/[id].vue?macro=true";
import { default as indexDVtE5oRPIbMeta } from "/vercel/path0/pages/manage/checklists/index.vue?macro=true";
import { default as add7NdVSfQQSQMeta } from "/vercel/path0/pages/manage/comments/add.vue?macro=true";
import { default as addgKg3R12K0NMeta } from "/vercel/path0/pages/manage/comments/bulk/add.vue?macro=true";
import { default as _91id_938d0p1j3t25Meta } from "/vercel/path0/pages/manage/comments/edit/[id].vue?macro=true";
import { default as indexVzGFsZapW0Meta } from "/vercel/path0/pages/manage/comments/index.vue?macro=true";
import { default as addzi9uKuHr2nMeta } from "/vercel/path0/pages/manage/criteria/add.vue?macro=true";
import { default as _91id_93m9HNG8W939Meta } from "/vercel/path0/pages/manage/criteria/edit/[id].vue?macro=true";
import { default as addbAk5xzatKeMeta } from "/vercel/path0/pages/manage/criteria/groups/add.vue?macro=true";
import { default as _91id_93wSPKaJIIhlMeta } from "/vercel/path0/pages/manage/criteria/groups/edit/[id].vue?macro=true";
import { default as indexkbQHXcyujBMeta } from "/vercel/path0/pages/manage/criteria/groups/index.vue?macro=true";
import { default as indexbFakB5ZsQpMeta } from "/vercel/path0/pages/manage/criteria/index.vue?macro=true";
import { default as wordsXz7K2q0s5xMeta } from "/vercel/path0/pages/manage/dictionary/words.vue?macro=true";
import { default as addfEg6PtsTmYMeta } from "/vercel/path0/pages/manage/lessons/add.vue?macro=true";
import { default as _91id_93a8JWuONhcnMeta } from "/vercel/path0/pages/manage/lessons/edit/[id].vue?macro=true";
import { default as indexuHHjTuZ2XyMeta } from "/vercel/path0/pages/manage/lessons/index.vue?macro=true";
import { default as addJmzDxuATVxMeta } from "/vercel/path0/pages/manage/levels/add.vue?macro=true";
import { default as _91id_9378mubDr7zaMeta } from "/vercel/path0/pages/manage/levels/edit/[id].vue?macro=true";
import { default as indexUTFi1Tq94LMeta } from "/vercel/path0/pages/manage/levels/index.vue?macro=true";
import { default as addXSFv8t9bESMeta } from "/vercel/path0/pages/manage/metrics/add.vue?macro=true";
import { default as _91id_93yvdfJZOiSJMeta } from "/vercel/path0/pages/manage/metrics/edit/[id].vue?macro=true";
import { default as indexRN7vWYrkffMeta } from "/vercel/path0/pages/manage/metrics/index.vue?macro=true";
import { default as addHtRN6eNiOSMeta } from "/vercel/path0/pages/manage/organizations/add.vue?macro=true";
import { default as _91id_93dHetZfEfysMeta } from "/vercel/path0/pages/manage/organizations/edit/[id].vue?macro=true";
import { default as indexS8i5h04nNpMeta } from "/vercel/path0/pages/manage/organizations/index.vue?macro=true";
import { default as adduzxcuhsqdZMeta } from "/vercel/path0/pages/manage/packages/[version]/add.vue?macro=true";
import { default as _91id_93cZ4Fm5o7bHMeta } from "/vercel/path0/pages/manage/packages/[version]/edit/[id].vue?macro=true";
import { default as _91version_93ckCeypwN6rMeta } from "/vercel/path0/pages/manage/packages/list/[version].vue?macro=true";
import { default as listtHN2xQK9jmMeta } from "/vercel/path0/pages/manage/packages/list.vue?macro=true";
import { default as addyVMf3ci1PqMeta } from "/vercel/path0/pages/manage/permissions/add.vue?macro=true";
import { default as _91id_93oTsMKW2yd2Meta } from "/vercel/path0/pages/manage/permissions/edit/[id].vue?macro=true";
import { default as indexliLJYJHsdxMeta } from "/vercel/path0/pages/manage/permissions/index.vue?macro=true";
import { default as addTUtP6jBjxxMeta } from "/vercel/path0/pages/manage/products/add.vue?macro=true";
import { default as _91id_93mGFaEeEz6fMeta } from "/vercel/path0/pages/manage/products/edit/[id].vue?macro=true";
import { default as indexwKnJBwzqqNMeta } from "/vercel/path0/pages/manage/products/index.vue?macro=true";
import { default as addivmlevTcHqMeta } from "/vercel/path0/pages/manage/roles/add.vue?macro=true";
import { default as _91id_93Wo1dE4FEJTMeta } from "/vercel/path0/pages/manage/roles/edit/[id].vue?macro=true";
import { default as indexn0L5cskkFfMeta } from "/vercel/path0/pages/manage/roles/index.vue?macro=true";
import { default as _91userId_93yrANAcuyIOMeta } from "/vercel/path0/pages/manage/users/[userId].vue?macro=true";
import { default as indexI2nPRjkP2qMeta } from "/vercel/path0/pages/manage/users/index.vue?macro=true";
import { default as googlehiZcKiLWJrMeta } from "/vercel/path0/pages/oauth/finalize/google.vue?macro=true";
import { default as zoomvVlU9z6EGaMeta } from "/vercel/path0/pages/oauth/finalize/zoom.vue?macro=true";
import { default as checkoutwc2n3q7doRMeta } from "/vercel/path0/pages/order/[cartType]/[cartId]/checkout.vue?macro=true";
import { default as configure5SIAV09BGYMeta } from "/vercel/path0/pages/order/[cartType]/[cartId]/configure.vue?macro=true";
import { default as submitTGj3hhLZHkMeta } from "/vercel/path0/pages/order/[cartType]/[cartId]/submit.vue?macro=true";
import { default as createmKF6PVNf73Meta } from "/vercel/path0/pages/order/[cartType]/create.vue?macro=true";
import { default as success5br2cxMMkZMeta } from "/vercel/path0/pages/order/[cartType]/success.vue?macro=true";
import { default as cookie_45policy6g4vkXeZlMMeta } from "/vercel/path0/pages/policies/cookie-policy.vue?macro=true";
import { default as privacy_45policyzfE8woLBQzMeta } from "/vercel/path0/pages/policies/privacy-policy.vue?macro=true";
import { default as terms_45and_45conditionsjmkJ9V4b3dMeta } from "/vercel/path0/pages/policies/terms-and-conditions.vue?macro=true";
import { default as statisticsPkoqJkgjjOMeta } from "/vercel/path0/pages/reports/assignment/statistics.vue?macro=true";
import { default as performanceBMZlfShe2nMeta } from "/vercel/path0/pages/reports/classroom/performance.vue?macro=true";
import { default as documents_45sync1i6bbE0naKMeta } from "/vercel/path0/pages/reports/documents-sync.vue?macro=true";
import { default as link_45usersrbSSrrD2C1Meta } from "/vercel/path0/pages/reports/link-users.vue?macro=true";
import { default as problemsMBJLfjVUtIMeta } from "/vercel/path0/pages/reports/problems.vue?macro=true";
import { default as subscriber_45healthsErgCRZienMeta } from "/vercel/path0/pages/reports/subscriber-health.vue?macro=true";
import { default as indexyyO1kiiaeGMeta } from "/vercel/path0/pages/reports/tester/index.vue?macro=true";
import { default as jiralPXQwpCD6aMeta } from "/vercel/path0/pages/reports/tester/jira.vue?macro=true";
import { default as usageHuBWYIgrixMeta } from "/vercel/path0/pages/reports/usage.vue?macro=true";
import { default as user_45permissions4eChCLYBNwMeta } from "/vercel/path0/pages/reports/user-permissions.vue?macro=true";
import { default as app_45data08YPytUelYMeta } from "/vercel/path0/pages/settings/app-data.vue?macro=true";
import { default as checklists7qYQ9K714fMeta } from "/vercel/path0/pages/settings/checklists.vue?macro=true";
import { default as indexKIRFIU1ID4Meta } from "/vercel/path0/pages/settings/index.vue?macro=true";
import { default as finalizeOoWB44ZpMJMeta } from "/vercel/path0/pages/settings/lms/finalize.vue?macro=true";
import { default as reports1f8n61sYlDMeta } from "/vercel/path0/pages/settings/reports.vue?macro=true";
import { default as settingsyZZu4Rd1aAMeta } from "/vercel/path0/pages/settings.vue?macro=true";
import { default as _91token_93GYA3ovRxNMMeta } from "/vercel/path0/pages/students/activate/[token].vue?macro=true";
import { default as activate_45accountzLEUZujshaMeta } from "/vercel/path0/pages/students/auth/activate-account.vue?macro=true";
import { default as forgot_45passwordG1v6GGpcYBMeta } from "/vercel/path0/pages/students/auth/forgot-password.vue?macro=true";
import { default as loginlz0Jvy5I7uMeta } from "/vercel/path0/pages/students/auth/login.vue?macro=true";
import { default as reset_45password2DhdPUacVyMeta } from "/vercel/path0/pages/students/auth/reset-password.vue?macro=true";
import { default as _91uid_93ViK449YGxdMeta } from "/vercel/path0/pages/students/bulk-add/[classroomId]/[uid].vue?macro=true";
import { default as _91classroomId_93XdfchEKgSDMeta } from "/vercel/path0/pages/students/bulk-add/generate/[classroomId].vue?macro=true";
import { default as _91documentId_93fS8STeehfMMeta } from "/vercel/path0/pages/students/documents/student/review/[documentId].vue?macro=true";
import { default as submitteddbbZsBQKroMeta } from "/vercel/path0/pages/students/documents/student/submitted.vue?macro=true";
import { default as error7lOb1AlSPFMeta } from "/vercel/path0/pages/students/error.vue?macro=true";
import { default as home23pOOlYV75Meta } from "/vercel/path0/pages/students/home.vue?macro=true";
import { default as indexgabsFWvsCkMeta } from "/vercel/path0/pages/students/index.vue?macro=true";
import { default as _91id_933p9Xa2m9jjMeta } from "/vercel/path0/pages/students/lms/emails/[id].vue?macro=true";
import { default as cookie_45policyle65G1Bg6nMeta } from "/vercel/path0/pages/students/policies/cookie-policy.vue?macro=true";
import { default as privacy_45policyyuiRR060TFMeta } from "/vercel/path0/pages/students/policies/privacy-policy.vue?macro=true";
import { default as terms_45and_45conditions8zM5tgVLEXMeta } from "/vercel/path0/pages/students/policies/terms-and-conditions.vue?macro=true";
import { default as marketingn16d1jsYm8Meta } from "/vercel/path0/pages/test/marketing.vue?macro=true";
import { default as onboardingFlf0inrqXcMeta } from "/vercel/path0/pages/welcome/onboarding.vue?macro=true";
export default [
  {
    name: "admin-checklists-review",
    path: "/admin/checklists/review",
    meta: reviewTW2pVHaqKuMeta || {},
    component: () => import("/vercel/path0/pages/admin/checklists/review.vue")
  },
  {
    name: "admin-dashboard",
    path: "/admin/dashboard",
    meta: dashboardYi2jyZZMHDMeta || {},
    component: () => import("/vercel/path0/pages/admin/dashboard.vue")
  },
  {
    name: "admin-lms-test",
    path: "/admin/lms/test",
    meta: testuxNnS6TxcPMeta || {},
    component: () => import("/vercel/path0/pages/admin/lms/test.vue")
  },
  {
    name: "articles",
    path: "/articles",
    meta: indexHSIYyOmJPsMeta || {},
    component: () => import("/vercel/path0/pages/articles/index.vue")
  },
  {
    name: "articles-view-articleId",
    path: "/articles/view/:articleId()",
    meta: _91articleId_93jynFiE1eWBMeta || {},
    component: () => import("/vercel/path0/pages/articles/view/[articleId].vue")
  },
  {
    name: "auth-activate-account",
    path: "/auth/activate-account",
    component: () => import("/vercel/path0/pages/auth/activate-account.vue")
  },
  {
    name: "auth-demo-login",
    path: "/auth/demo-login",
    component: () => import("/vercel/path0/pages/auth/demo-login.vue")
  },
  {
    name: "auth-error",
    path: "/auth/error",
    component: () => import("/vercel/path0/pages/auth/error.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordRoBld5gkuaMeta || {},
    component: () => import("/vercel/path0/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-Login",
    path: "/auth/Login",
    meta: Login716SBvoAAxMeta || {},
    component: () => import("/vercel/path0/pages/auth/Login.vue")
  },
  {
    name: "auth-oauth",
    path: "/auth/oauth",
    component: () => import("/vercel/path0/pages/auth/oauth.vue")
  },
  {
    name: "auth-Register",
    path: "/auth/Register",
    meta: RegisterD36lJ5bfN2Meta || {},
    component: () => import("/vercel/path0/pages/auth/Register.vue")
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    meta: reset_45passwordNF4wjmDL6XMeta || {},
    component: () => import("/vercel/path0/pages/auth/reset-password.vue")
  },
  {
    name: "classrooms-classroomId",
    path: "/classrooms/:classroomId()",
    meta: _91classroomId_93vyhfg9rdTvMeta || {},
    component: () => import("/vercel/path0/pages/classrooms/[classroomId].vue"),
    children: [
  {
    name: "classrooms-classroomId-assignments",
    path: "assignments",
    component: () => import("/vercel/path0/pages/classrooms/[classroomId]/assignments.vue"),
    children: [
  {
    name: "classrooms-classroomId-assignments-assignmentId",
    path: ":assignmentId()",
    component: () => import("/vercel/path0/pages/classrooms/[classroomId]/assignments/[assignmentId].vue")
  }
]
  },
  {
    name: "classrooms-classroomId-class-sessions",
    path: "class-sessions",
    component: () => import("/vercel/path0/pages/classrooms/[classroomId]/class-sessions.vue"),
    children: [
  {
    name: "classrooms-classroomId-class-sessions-classSessionId",
    path: ":classSessionId()",
    component: () => import("/vercel/path0/pages/classrooms/[classroomId]/class-sessions/[classSessionId].vue")
  }
]
  },
  {
    name: "classrooms-classroomId-emails-add",
    path: "emails/add",
    component: () => import("/vercel/path0/pages/classrooms/[classroomId]/emails/add.vue")
  },
  {
    name: "classrooms-classroomId-emails-edit-emailId",
    path: "emails/edit/:emailId()",
    meta: _91emailId_9350nhuLfzUJMeta || {},
    component: () => import("/vercel/path0/pages/classrooms/[classroomId]/emails/edit/[emailId].vue")
  },
  {
    name: "classrooms-classroomId-emails",
    path: "emails",
    component: () => import("/vercel/path0/pages/classrooms/[classroomId]/emails/index.vue")
  },
  {
    name: "classrooms-classroomId-gradebook",
    path: "gradebook",
    component: () => import("/vercel/path0/pages/classrooms/[classroomId]/gradebook/index.vue")
  },
  {
    name: "classrooms-classroomId-report-card-studentId",
    path: "report-card/:studentId()",
    component: () => import("/vercel/path0/pages/classrooms/[classroomId]/report-card/[studentId].vue")
  },
  {
    name: "classrooms-classroomId-resources",
    path: "resources",
    component: () => import("/vercel/path0/pages/classrooms/[classroomId]/resources/index.vue")
  },
  {
    name: "classrooms-classroomId-students",
    path: "students",
    component: () => import("/vercel/path0/pages/classrooms/[classroomId]/students.vue"),
    children: [
  {
    name: "classrooms-classroomId-students-studentId",
    path: ":studentId()",
    component: () => import("/vercel/path0/pages/classrooms/[classroomId]/students/[studentId].vue")
  }
]
  },
  {
    name: "classrooms-classroomId-style",
    path: "style",
    component: () => import("/vercel/path0/pages/classrooms/[classroomId]/style/index.vue")
  }
]
  },
  {
    name: "classrooms-emails-id-edit",
    path: "/classrooms/emails/:id()/edit",
    meta: editExUaEm6z8OMeta || {},
    component: () => import("/vercel/path0/pages/classrooms/emails/[id]/edit.vue")
  },
  {
    name: "classrooms-emails-add",
    path: "/classrooms/emails/add",
    meta: addHJS66JjvSwMeta || {},
    component: () => import("/vercel/path0/pages/classrooms/emails/add.vue")
  },
  {
    name: "classrooms-emails",
    path: "/classrooms/emails",
    meta: indexA4Qwm6PMCDMeta || {},
    component: () => import("/vercel/path0/pages/classrooms/emails/index.vue")
  },
  {
    name: "configure-classrooms-add",
    path: "/configure/classrooms/add",
    meta: addw0JPeMCrVTMeta || {},
    component: () => import("/vercel/path0/pages/configure/classrooms/add.vue")
  },
  {
    name: "configure-comments-id-edit",
    path: "/configure/comments/:id()/edit",
    meta: edite89g2exz5uMeta || {},
    component: () => import("/vercel/path0/pages/configure/comments/[id]/edit.vue")
  },
  {
    name: "configure-comments-add",
    path: "/configure/comments/add",
    meta: addD5aYXRa6ARMeta || {},
    component: () => import("/vercel/path0/pages/configure/comments/add.vue")
  },
  {
    name: "configure-comments-bulk-add",
    path: "/configure/comments/bulk/add",
    meta: addfnyrPWXnFaMeta || {},
    component: () => import("/vercel/path0/pages/configure/comments/bulk/add.vue")
  },
  {
    name: "configure-comments-bulk-import",
    path: "/configure/comments/bulk/import",
    meta: importvwD2tL0BWlMeta || {},
    component: () => import("/vercel/path0/pages/configure/comments/bulk/import.vue")
  },
  {
    name: "configure-comments",
    path: "/configure/comments",
    meta: index24bk44ADpnMeta || {},
    component: () => import("/vercel/path0/pages/configure/comments/index.vue")
  },
  {
    name: "configure-curriculum",
    path: "/configure/curriculum",
    meta: curriculumal1D3It1UzMeta || {},
    component: () => import("/vercel/path0/pages/configure/curriculum.vue")
  },
  {
    name: "configure-dictionary-words",
    path: "/configure/dictionary/words",
    meta: wordsW188IV4EzxMeta || {},
    component: () => import("/vercel/path0/pages/configure/dictionary/words.vue")
  },
  {
    name: "configure-lms-assignments",
    path: "/configure/lms/assignments",
    meta: assignmentsW9OEBL8xgEMeta || {},
    component: () => import("/vercel/path0/pages/configure/lms/assignments.vue")
  },
  {
    name: "configure-lms-choose",
    path: "/configure/lms/choose",
    meta: choosex593Mk9uekMeta || {},
    component: () => import("/vercel/path0/pages/configure/lms/choose.vue")
  },
  {
    name: "configure-lms-classrooms",
    path: "/configure/lms/classrooms",
    meta: classroomsQBVTjMZjMOMeta || {},
    component: () => import("/vercel/path0/pages/configure/lms/classrooms.vue")
  },
  {
    name: "configure-lms-finalize",
    path: "/configure/lms/finalize",
    meta: finalizekwDQA19yumMeta || {},
    component: () => import("/vercel/path0/pages/configure/lms/finalize.vue")
  },
  {
    name: "configure-lms-submissions",
    path: "/configure/lms/submissions",
    meta: submissionsU3JwXi0RCzMeta || {},
    component: () => import("/vercel/path0/pages/configure/lms/submissions.vue")
  },
  {
    name: "configure-organizations-edit-organizationId",
    path: "/configure/organizations/edit/:organizationId()",
    meta: _91organizationId_93gtvSG4BoPVMeta || {},
    component: () => import("/vercel/path0/pages/configure/organizations/edit/[organizationId].vue")
  },
  {
    name: "configure-organizations",
    path: "/configure/organizations",
    meta: index8NHHtGKS2CMeta || {},
    component: () => import("/vercel/path0/pages/configure/organizations/index.vue")
  },
  {
    name: "configure-password",
    path: "/configure/password",
    meta: password4LtnzSffadMeta || {},
    component: () => import("/vercel/path0/pages/configure/password.vue")
  },
  {
    name: "configure-profile-orders",
    path: "/configure/profile/orders",
    meta: indexspXjquMM6fMeta || {},
    component: () => import("/vercel/path0/pages/configure/profile/orders/index.vue")
  },
  {
    name: "configure-profile-payment-methods",
    path: "/configure/profile/payment-methods",
    meta: payment_45methods19vQ7r3TGiMeta || {},
    component: () => import("/vercel/path0/pages/configure/profile/payment-methods.vue")
  },
  {
    name: "configure-quick-keys",
    path: "/configure/quick-keys",
    meta: quick_45keysXq2kp9dEwkMeta || {},
    component: () => import("/vercel/path0/pages/configure/quick-keys.vue")
  },
  {
    name: "configure-subscription",
    path: "/configure/subscription",
    meta: subscriptioniugGyYh2wCMeta || {},
    component: () => import("/vercel/path0/pages/configure/subscription.vue")
  },
  {
    name: "convert",
    path: "/convert",
    component: () => import("/vercel/path0/pages/convert.vue")
  },
  {
    name: "document-documentId-revisionId-checklist-preview",
    path: "/document/:documentId()/:revisionId()/checklist/preview",
    component: () => import("/vercel/path0/pages/document/[documentId]/[revisionId]/checklist/preview.vue")
  },
  {
    name: "documents-mode-review-documentId",
    path: "/documents/:mode()/review/:documentId()",
    meta: _91documentId_93XOYQWNruB2Meta || {},
    component: () => import("/vercel/path0/pages/documents/[mode]/review/[documentId].vue")
  },
  {
    name: "documents-mode-submitted",
    path: "/documents/:mode()/submitted",
    meta: submittedigt0FHwdNHMeta || {},
    component: () => import("/vercel/path0/pages/documents/[mode]/submitted.vue")
  },
  {
    name: "errors-message",
    path: "/errors/message",
    component: () => import("/vercel/path0/pages/errors/message.vue")
  },
  {
    name: "home",
    path: "/home",
    meta: homeaAKJ71q3qIMeta || {},
    component: () => import("/vercel/path0/pages/home.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "level-documents-id",
    path: "/level/documents/:id()",
    meta: _91id_934fWRiA842XMeta || {},
    component: () => import("/vercel/path0/pages/level/documents/[id].vue")
  },
  {
    name: "lms",
    path: "/lms",
    component: () => import("/vercel/path0/pages/lms.vue")
  },
  {
    name: "manage-annotation-types-add",
    path: "/manage/annotation/types/add",
    meta: add7xh7tITbJuMeta || {},
    component: () => import("/vercel/path0/pages/manage/annotation/types/add.vue")
  },
  {
    name: "manage-annotation-types-edit-id",
    path: "/manage/annotation/types/edit/:id()",
    meta: _91id_93BOgu1adRobMeta || {},
    component: () => import("/vercel/path0/pages/manage/annotation/types/edit/[id].vue")
  },
  {
    name: "manage-annotation-types",
    path: "/manage/annotation/types",
    meta: indexaE4rEjLhVHMeta || {},
    component: () => import("/vercel/path0/pages/manage/annotation/types/index.vue")
  },
  {
    name: "manage-announcements-add",
    path: "/manage/announcements/add",
    meta: add0to5VaVcSgMeta || {},
    component: () => import("/vercel/path0/pages/manage/announcements/add.vue")
  },
  {
    name: "manage-announcements-edit-id",
    path: "/manage/announcements/edit/:id()",
    meta: _91id_93SRr64J7d2OMeta || {},
    component: () => import("/vercel/path0/pages/manage/announcements/edit/[id].vue")
  },
  {
    name: "manage-announcements",
    path: "/manage/announcements",
    meta: indexoTzbXJSpIlMeta || {},
    component: () => import("/vercel/path0/pages/manage/announcements/index.vue")
  },
  {
    name: "manage-articles-add",
    path: "/manage/articles/add",
    meta: addR25HqEBHH4Meta || {},
    component: () => import("/vercel/path0/pages/manage/articles/add.vue")
  },
  {
    name: "manage-articles-edit-id",
    path: "/manage/articles/edit/:id()",
    meta: _91id_93bhKSpAuvw6Meta || {},
    component: () => import("/vercel/path0/pages/manage/articles/edit/[id].vue")
  },
  {
    name: "manage-articles",
    path: "/manage/articles",
    meta: indexgskJXvA9EZMeta || {},
    component: () => import("/vercel/path0/pages/manage/articles/index.vue")
  },
  {
    name: "manage-capabilities-add",
    path: "/manage/capabilities/add",
    meta: addNn4UQp9ddpMeta || {},
    component: () => import("/vercel/path0/pages/manage/capabilities/add.vue")
  },
  {
    name: "manage-capabilities-edit-version",
    path: "/manage/capabilities/edit/:version()",
    meta: _91version_93ilDS13nyWPMeta || {},
    component: () => import("/vercel/path0/pages/manage/capabilities/edit/[version].vue")
  },
  {
    name: "manage-capabilities",
    path: "/manage/capabilities",
    meta: indexHWijtWCqqgMeta || {},
    component: () => import("/vercel/path0/pages/manage/capabilities/index.vue")
  },
  {
    name: "manage-checklists-add",
    path: "/manage/checklists/add",
    meta: addkFSdZnvL1zMeta || {},
    component: () => import("/vercel/path0/pages/manage/checklists/add.vue")
  },
  {
    name: "manage-checklists-edit-id",
    path: "/manage/checklists/edit/:id()",
    meta: _91id_935Kc2gxS0MQMeta || {},
    component: () => import("/vercel/path0/pages/manage/checklists/edit/[id].vue")
  },
  {
    name: "manage-checklists",
    path: "/manage/checklists",
    meta: indexDVtE5oRPIbMeta || {},
    component: () => import("/vercel/path0/pages/manage/checklists/index.vue")
  },
  {
    name: "manage-comments-add",
    path: "/manage/comments/add",
    meta: add7NdVSfQQSQMeta || {},
    component: () => import("/vercel/path0/pages/manage/comments/add.vue")
  },
  {
    name: "manage-comments-bulk-add",
    path: "/manage/comments/bulk/add",
    meta: addgKg3R12K0NMeta || {},
    component: () => import("/vercel/path0/pages/manage/comments/bulk/add.vue")
  },
  {
    name: "manage-comments-edit-id",
    path: "/manage/comments/edit/:id()",
    meta: _91id_938d0p1j3t25Meta || {},
    component: () => import("/vercel/path0/pages/manage/comments/edit/[id].vue")
  },
  {
    name: "manage-comments",
    path: "/manage/comments",
    meta: indexVzGFsZapW0Meta || {},
    component: () => import("/vercel/path0/pages/manage/comments/index.vue")
  },
  {
    name: "manage-criteria-add",
    path: "/manage/criteria/add",
    meta: addzi9uKuHr2nMeta || {},
    component: () => import("/vercel/path0/pages/manage/criteria/add.vue")
  },
  {
    name: "manage-criteria-edit-id",
    path: "/manage/criteria/edit/:id()",
    meta: _91id_93m9HNG8W939Meta || {},
    component: () => import("/vercel/path0/pages/manage/criteria/edit/[id].vue")
  },
  {
    name: "manage-criteria-groups-add",
    path: "/manage/criteria/groups/add",
    meta: addbAk5xzatKeMeta || {},
    component: () => import("/vercel/path0/pages/manage/criteria/groups/add.vue")
  },
  {
    name: "manage-criteria-groups-edit-id",
    path: "/manage/criteria/groups/edit/:id()",
    meta: _91id_93wSPKaJIIhlMeta || {},
    component: () => import("/vercel/path0/pages/manage/criteria/groups/edit/[id].vue")
  },
  {
    name: "manage-criteria-groups",
    path: "/manage/criteria/groups",
    meta: indexkbQHXcyujBMeta || {},
    component: () => import("/vercel/path0/pages/manage/criteria/groups/index.vue")
  },
  {
    name: "manage-criteria",
    path: "/manage/criteria",
    meta: indexbFakB5ZsQpMeta || {},
    component: () => import("/vercel/path0/pages/manage/criteria/index.vue")
  },
  {
    name: "manage-dictionary-words",
    path: "/manage/dictionary/words",
    meta: wordsXz7K2q0s5xMeta || {},
    component: () => import("/vercel/path0/pages/manage/dictionary/words.vue")
  },
  {
    name: "manage-lessons-add",
    path: "/manage/lessons/add",
    meta: addfEg6PtsTmYMeta || {},
    component: () => import("/vercel/path0/pages/manage/lessons/add.vue")
  },
  {
    name: "manage-lessons-edit-id",
    path: "/manage/lessons/edit/:id()",
    meta: _91id_93a8JWuONhcnMeta || {},
    component: () => import("/vercel/path0/pages/manage/lessons/edit/[id].vue")
  },
  {
    name: "manage-lessons",
    path: "/manage/lessons",
    meta: indexuHHjTuZ2XyMeta || {},
    component: () => import("/vercel/path0/pages/manage/lessons/index.vue")
  },
  {
    name: "manage-levels-add",
    path: "/manage/levels/add",
    meta: addJmzDxuATVxMeta || {},
    component: () => import("/vercel/path0/pages/manage/levels/add.vue")
  },
  {
    name: "manage-levels-edit-id",
    path: "/manage/levels/edit/:id()",
    meta: _91id_9378mubDr7zaMeta || {},
    component: () => import("/vercel/path0/pages/manage/levels/edit/[id].vue")
  },
  {
    name: "manage-levels",
    path: "/manage/levels",
    meta: indexUTFi1Tq94LMeta || {},
    component: () => import("/vercel/path0/pages/manage/levels/index.vue")
  },
  {
    name: "manage-metrics-add",
    path: "/manage/metrics/add",
    meta: addXSFv8t9bESMeta || {},
    component: () => import("/vercel/path0/pages/manage/metrics/add.vue")
  },
  {
    name: "manage-metrics-edit-id",
    path: "/manage/metrics/edit/:id()",
    meta: _91id_93yvdfJZOiSJMeta || {},
    component: () => import("/vercel/path0/pages/manage/metrics/edit/[id].vue")
  },
  {
    name: "manage-metrics",
    path: "/manage/metrics",
    meta: indexRN7vWYrkffMeta || {},
    component: () => import("/vercel/path0/pages/manage/metrics/index.vue")
  },
  {
    name: "manage-organizations-add",
    path: "/manage/organizations/add",
    meta: addHtRN6eNiOSMeta || {},
    component: () => import("/vercel/path0/pages/manage/organizations/add.vue")
  },
  {
    name: "manage-organizations-edit-id",
    path: "/manage/organizations/edit/:id()",
    meta: _91id_93dHetZfEfysMeta || {},
    component: () => import("/vercel/path0/pages/manage/organizations/edit/[id].vue")
  },
  {
    name: "manage-organizations",
    path: "/manage/organizations",
    meta: indexS8i5h04nNpMeta || {},
    component: () => import("/vercel/path0/pages/manage/organizations/index.vue")
  },
  {
    name: "manage-packages-version-add",
    path: "/manage/packages/:version()/add",
    meta: adduzxcuhsqdZMeta || {},
    component: () => import("/vercel/path0/pages/manage/packages/[version]/add.vue")
  },
  {
    name: "manage-packages-version-edit-id",
    path: "/manage/packages/:version()/edit/:id()",
    meta: _91id_93cZ4Fm5o7bHMeta || {},
    component: () => import("/vercel/path0/pages/manage/packages/[version]/edit/[id].vue")
  },
  {
    name: "manage-packages-list",
    path: "/manage/packages/list",
    meta: listtHN2xQK9jmMeta || {},
    component: () => import("/vercel/path0/pages/manage/packages/list.vue"),
    children: [
  {
    name: "manage-packages-list-version",
    path: ":version()",
    component: () => import("/vercel/path0/pages/manage/packages/list/[version].vue")
  }
]
  },
  {
    name: "manage-permissions-add",
    path: "/manage/permissions/add",
    meta: addyVMf3ci1PqMeta || {},
    component: () => import("/vercel/path0/pages/manage/permissions/add.vue")
  },
  {
    name: "manage-permissions-edit-id",
    path: "/manage/permissions/edit/:id()",
    meta: _91id_93oTsMKW2yd2Meta || {},
    component: () => import("/vercel/path0/pages/manage/permissions/edit/[id].vue")
  },
  {
    name: "manage-permissions",
    path: "/manage/permissions",
    meta: indexliLJYJHsdxMeta || {},
    component: () => import("/vercel/path0/pages/manage/permissions/index.vue")
  },
  {
    name: "manage-products-add",
    path: "/manage/products/add",
    meta: addTUtP6jBjxxMeta || {},
    component: () => import("/vercel/path0/pages/manage/products/add.vue")
  },
  {
    name: "manage-products-edit-id",
    path: "/manage/products/edit/:id()",
    meta: _91id_93mGFaEeEz6fMeta || {},
    component: () => import("/vercel/path0/pages/manage/products/edit/[id].vue")
  },
  {
    name: "manage-products",
    path: "/manage/products",
    meta: indexwKnJBwzqqNMeta || {},
    component: () => import("/vercel/path0/pages/manage/products/index.vue")
  },
  {
    name: "manage-roles-add",
    path: "/manage/roles/add",
    meta: addivmlevTcHqMeta || {},
    component: () => import("/vercel/path0/pages/manage/roles/add.vue")
  },
  {
    name: "manage-roles-edit-id",
    path: "/manage/roles/edit/:id()",
    meta: _91id_93Wo1dE4FEJTMeta || {},
    component: () => import("/vercel/path0/pages/manage/roles/edit/[id].vue")
  },
  {
    name: "manage-roles",
    path: "/manage/roles",
    meta: indexn0L5cskkFfMeta || {},
    component: () => import("/vercel/path0/pages/manage/roles/index.vue")
  },
  {
    name: "manage-users-userId",
    path: "/manage/users/:userId()",
    meta: _91userId_93yrANAcuyIOMeta || {},
    component: () => import("/vercel/path0/pages/manage/users/[userId].vue")
  },
  {
    name: "manage-users",
    path: "/manage/users",
    meta: indexI2nPRjkP2qMeta || {},
    component: () => import("/vercel/path0/pages/manage/users/index.vue")
  },
  {
    name: "oauth-finalize-google",
    path: "/oauth/finalize/google",
    meta: googlehiZcKiLWJrMeta || {},
    component: () => import("/vercel/path0/pages/oauth/finalize/google.vue")
  },
  {
    name: "oauth-finalize-zoom",
    path: "/oauth/finalize/zoom",
    meta: zoomvVlU9z6EGaMeta || {},
    component: () => import("/vercel/path0/pages/oauth/finalize/zoom.vue")
  },
  {
    name: "order-cartType-cartId-checkout",
    path: "/order/:cartType()/:cartId()/checkout",
    meta: checkoutwc2n3q7doRMeta || {},
    component: () => import("/vercel/path0/pages/order/[cartType]/[cartId]/checkout.vue")
  },
  {
    name: "order-cartType-cartId-configure",
    path: "/order/:cartType()/:cartId()/configure",
    meta: configure5SIAV09BGYMeta || {},
    component: () => import("/vercel/path0/pages/order/[cartType]/[cartId]/configure.vue")
  },
  {
    name: "order-cartType-cartId-submit",
    path: "/order/:cartType()/:cartId()/submit",
    meta: submitTGj3hhLZHkMeta || {},
    component: () => import("/vercel/path0/pages/order/[cartType]/[cartId]/submit.vue")
  },
  {
    name: "order-cartType-create",
    path: "/order/:cartType()/create",
    meta: createmKF6PVNf73Meta || {},
    component: () => import("/vercel/path0/pages/order/[cartType]/create.vue")
  },
  {
    name: "order-cartType-success",
    path: "/order/:cartType()/success",
    component: () => import("/vercel/path0/pages/order/[cartType]/success.vue")
  },
  {
    name: "policies-cookie-policy",
    path: "/policies/cookie-policy",
    component: () => import("/vercel/path0/pages/policies/cookie-policy.vue")
  },
  {
    name: "policies-privacy-policy",
    path: "/policies/privacy-policy",
    component: () => import("/vercel/path0/pages/policies/privacy-policy.vue")
  },
  {
    name: "policies-terms-and-conditions",
    path: "/policies/terms-and-conditions",
    component: () => import("/vercel/path0/pages/policies/terms-and-conditions.vue")
  },
  {
    name: "reports-assignment-statistics",
    path: "/reports/assignment/statistics",
    meta: statisticsPkoqJkgjjOMeta || {},
    component: () => import("/vercel/path0/pages/reports/assignment/statistics.vue")
  },
  {
    name: "reports-classroom-performance",
    path: "/reports/classroom/performance",
    meta: performanceBMZlfShe2nMeta || {},
    component: () => import("/vercel/path0/pages/reports/classroom/performance.vue")
  },
  {
    name: "reports-documents-sync",
    path: "/reports/documents-sync",
    meta: documents_45sync1i6bbE0naKMeta || {},
    component: () => import("/vercel/path0/pages/reports/documents-sync.vue")
  },
  {
    name: "reports-link-users",
    path: "/reports/link-users",
    meta: link_45usersrbSSrrD2C1Meta || {},
    component: () => import("/vercel/path0/pages/reports/link-users.vue")
  },
  {
    name: "reports-problems",
    path: "/reports/problems",
    meta: problemsMBJLfjVUtIMeta || {},
    component: () => import("/vercel/path0/pages/reports/problems.vue")
  },
  {
    name: "reports-subscriber-health",
    path: "/reports/subscriber-health",
    meta: subscriber_45healthsErgCRZienMeta || {},
    component: () => import("/vercel/path0/pages/reports/subscriber-health.vue")
  },
  {
    name: "reports-tester",
    path: "/reports/tester",
    meta: indexyyO1kiiaeGMeta || {},
    component: () => import("/vercel/path0/pages/reports/tester/index.vue")
  },
  {
    name: "reports-tester-jira",
    path: "/reports/tester/jira",
    meta: jiralPXQwpCD6aMeta || {},
    component: () => import("/vercel/path0/pages/reports/tester/jira.vue")
  },
  {
    name: "reports-usage",
    path: "/reports/usage",
    meta: usageHuBWYIgrixMeta || {},
    component: () => import("/vercel/path0/pages/reports/usage.vue")
  },
  {
    name: "reports-user-permissions",
    path: "/reports/user-permissions",
    meta: user_45permissions4eChCLYBNwMeta || {},
    component: () => import("/vercel/path0/pages/reports/user-permissions.vue")
  },
  {
    name: settingsyZZu4Rd1aAMeta?.name,
    path: "/settings",
    meta: settingsyZZu4Rd1aAMeta || {},
    component: () => import("/vercel/path0/pages/settings.vue"),
    children: [
  {
    name: "settings-app-data",
    path: "app-data",
    component: () => import("/vercel/path0/pages/settings/app-data.vue")
  },
  {
    name: "settings-checklists",
    path: "checklists",
    component: () => import("/vercel/path0/pages/settings/checklists.vue")
  },
  {
    name: "settings",
    path: "",
    component: () => import("/vercel/path0/pages/settings/index.vue")
  },
  {
    name: "settings-lms-finalize",
    path: "lms/finalize",
    meta: finalizeOoWB44ZpMJMeta || {},
    component: () => import("/vercel/path0/pages/settings/lms/finalize.vue")
  },
  {
    name: "settings-reports",
    path: "reports",
    component: () => import("/vercel/path0/pages/settings/reports.vue")
  }
]
  },
  {
    name: "students-activate-token",
    path: "/students/activate/:token()",
    component: () => import("/vercel/path0/pages/students/activate/[token].vue")
  },
  {
    name: "students-auth-activate-account",
    path: "/students/auth/activate-account",
    component: () => import("/vercel/path0/pages/students/auth/activate-account.vue")
  },
  {
    name: "students-auth-forgot-password",
    path: "/students/auth/forgot-password",
    component: () => import("/vercel/path0/pages/students/auth/forgot-password.vue")
  },
  {
    name: "students-auth-login",
    path: "/students/auth/login",
    component: () => import("/vercel/path0/pages/students/auth/login.vue")
  },
  {
    name: "students-auth-reset-password",
    path: "/students/auth/reset-password",
    component: () => import("/vercel/path0/pages/students/auth/reset-password.vue")
  },
  {
    name: "students-bulk-add-classroomId-uid",
    path: "/students/bulk-add/:classroomId()/:uid()",
    meta: _91uid_93ViK449YGxdMeta || {},
    component: () => import("/vercel/path0/pages/students/bulk-add/[classroomId]/[uid].vue")
  },
  {
    name: "students-bulk-add-generate-classroomId",
    path: "/students/bulk-add/generate/:classroomId()",
    meta: _91classroomId_93XdfchEKgSDMeta || {},
    component: () => import("/vercel/path0/pages/students/bulk-add/generate/[classroomId].vue")
  },
  {
    name: "students-documents-student-review-documentId",
    path: "/students/documents/student/review/:documentId()",
    meta: _91documentId_93fS8STeehfMMeta || {},
    component: () => import("/vercel/path0/pages/students/documents/student/review/[documentId].vue")
  },
  {
    name: "students-documents-student-submitted",
    path: "/students/documents/student/submitted",
    meta: submitteddbbZsBQKroMeta || {},
    component: () => import("/vercel/path0/pages/students/documents/student/submitted.vue")
  },
  {
    name: "students-error",
    path: "/students/error",
    component: () => import("/vercel/path0/pages/students/error.vue")
  },
  {
    name: "students-home",
    path: "/students/home",
    component: () => import("/vercel/path0/pages/students/home.vue")
  },
  {
    name: "students",
    path: "/students",
    meta: indexgabsFWvsCkMeta || {},
    component: () => import("/vercel/path0/pages/students/index.vue")
  },
  {
    name: "students-lms-emails-id",
    path: "/students/lms/emails/:id()",
    meta: _91id_933p9Xa2m9jjMeta || {},
    component: () => import("/vercel/path0/pages/students/lms/emails/[id].vue")
  },
  {
    name: "students-policies-cookie-policy",
    path: "/students/policies/cookie-policy",
    component: () => import("/vercel/path0/pages/students/policies/cookie-policy.vue")
  },
  {
    name: "students-policies-privacy-policy",
    path: "/students/policies/privacy-policy",
    component: () => import("/vercel/path0/pages/students/policies/privacy-policy.vue")
  },
  {
    name: "students-policies-terms-and-conditions",
    path: "/students/policies/terms-and-conditions",
    component: () => import("/vercel/path0/pages/students/policies/terms-and-conditions.vue")
  },
  {
    name: "test-marketing",
    path: "/test/marketing",
    component: () => import("/vercel/path0/pages/test/marketing.vue")
  },
  {
    name: "welcome-onboarding",
    path: "/welcome/onboarding",
    meta: onboardingFlf0inrqXcMeta || {},
    component: () => import("/vercel/path0/pages/welcome/onboarding.vue")
  }
]