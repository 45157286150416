import { ChecklistOptionOverrides } from "types/Checklist";
import {
  EvaluationCriteriaResult
} from "./evaluation_criteria_result";
import { RelevantInstances } from "./relevant_instance";

export type EvaluationCriteriaList = EvaluationCriteria[];

export enum EvaluationMethod {
  all = "all",
  any = "any",
}

export class EvaluationCriteria {
  id: string;
  uid: string;
  text: string;
  tag: string | undefined;
  options: { [key: string]: any };
  acceptWordList: boolean;
  acceptParagraphPoints: boolean;
  manualInstanceReview: boolean;
  isMechanics: boolean; // This will change how the criteria renders and how the score is calculated
  enableTypeSelection: boolean; // This will allow a user to configure one or more type that can be confiugred in order to classify a relevant instance and determine whether it is correct
  preserveInstanceFormatting: boolean;
  useWordsToIgnore: boolean;
  isVisible: boolean;
  isTopLevelCriteria: boolean;
  shouldShowInstances: boolean;
  shouldShowToStudents: boolean;
  shouldHideExtraInstances: boolean;
  expandByDefault: boolean;
  forceOpen: boolean;
  highlightInstances: boolean;
  hidePassLabel: boolean;
  isManual: boolean;
  isValidByDefault: boolean;
  reversePassFail: boolean;
  evaluationMethod: EvaluationMethod;
  instanceLabelGenerator: any;
  criteria: EvaluationCriteriaList;
  missingInstancesMessage?: string;
  valid: boolean;
  relevantInstances: RelevantInstances;
  instanceLabelOverride?: string;
  results?: EvaluationCriteriaResult;
  optionDescriptionOverrideMap?: { [key: string]: string };

  constructor(
    id: string,
    uid: string,
    text: string,
    tag: string | undefined,
    options: { [key: string]: any },
    acceptWordList: boolean,
    acceptParagraphPoints: boolean,
    manualInstanceReview: boolean,
    isMechanics: boolean,
    enableTypeSelection: boolean,
    preserveInstanceFormatting: boolean,
    useWordsToIgnore: boolean,
    isVisible: boolean,
    isTopLevelCriteria: boolean,
    shouldShowInstances: boolean,
    shouldShowToStudents: boolean,
    shouldHideExtraInstances: boolean,
    expandByDefault: boolean,
    forceOpen: boolean,
    highlightInstances: boolean,
    hidePassLabel: boolean,
    isManual: boolean,
    isValidByDefault: boolean,
    reversePassFail: boolean,
    evaluationMethod: EvaluationMethod,
    instanceLabelGenerator: any,
    criteriaList: any[],
    instanceLabelOverride?: string,
    missingInstancesMessage?: string,
    results?: EvaluationCriteriaResult,
    optionDescriptionOverrideMap?: { [key: string]: string }
  ) {
    this.id = id;
    this.uid = uid;
    this.text = text;
    this.tag = tag;
    this.options = options;
    this.acceptWordList = acceptWordList;
    this.acceptParagraphPoints = acceptParagraphPoints;
    this.manualInstanceReview = manualInstanceReview;
    this.isMechanics = isMechanics;
    this.enableTypeSelection = enableTypeSelection;
    this.preserveInstanceFormatting = preserveInstanceFormatting;
    this.useWordsToIgnore = useWordsToIgnore;
    this.isVisible = isVisible;
    this.isTopLevelCriteria = isTopLevelCriteria;
    this.shouldShowInstances = shouldShowInstances;
    this.shouldShowToStudents = shouldShowToStudents;
    this.shouldHideExtraInstances = shouldHideExtraInstances;
    this.expandByDefault = expandByDefault;
    this.forceOpen = forceOpen;
    this.highlightInstances = highlightInstances;
    this.hidePassLabel = hidePassLabel;
    this.isManual = isManual;
    this.isValidByDefault = isValidByDefault;
    this.reversePassFail = reversePassFail;
    this.evaluationMethod = evaluationMethod;
    this.instanceLabelGenerator = instanceLabelGenerator;
    this.valid = false;
    this.relevantInstances = [];
    this.instanceLabelOverride = instanceLabelOverride;
    this.missingInstancesMessage = missingInstancesMessage;
    this.results = results;
    this.optionDescriptionOverrideMap = optionDescriptionOverrideMap;

    this.criteria = criteriaList.map((criteria: any, index: number) => {
      return new EvaluationCriteria(
        criteria.id,
        criteria.uid,
        criteria.text,
        criteria.tag,
        criteria.options,
        criteria.acceptWordList,
        criteria.acceptParagraphPoints,
        criteria.manualInstanceReview,
        criteria.isMechanics,
        criteria.enableTypeSelection,
        criteria.preserveInstanceFormatting,
        criteria.useWordsToIgnore,
        criteria.isVisible,
        criteria.isTopLevelCriteria,
        criteria.shouldShowInstances,
        criteria.shouldShowToStudents,
        criteria.shouldHideExtraInstances,
        criteria.expandByDefault,
        criteria.forceOpen,
        criteria.highlightInstances,
        criteria.hidePassLabel,
        criteria.isManual,
        criteria.isValidByDefault,
        criteria.reversePassFail,
        criteria.evaluationMethod,
        criteria.instanceLabelGenerator,
        criteria.criteria,
        criteria.instanceLabelOverride,
        criteria.missingInstancesMessage,
        criteria.results,
        criteria.optionDescriptionOverrideMap
      );
    });
  }

  copy(): EvaluationCriteria {
    return new EvaluationCriteria(
      this.id,
      this.uid,
      this.text,
      this.tag,
      this.options,
      this.acceptWordList,
      this.acceptParagraphPoints,
      this.manualInstanceReview,
      this.isMechanics,
      this.enableTypeSelection,
      this.preserveInstanceFormatting,
      this.useWordsToIgnore,
      this.isVisible,
      this.isTopLevelCriteria,
      this.shouldShowInstances,
      this.shouldShowToStudents,
      this.shouldHideExtraInstances,
      this.expandByDefault,
      this.forceOpen,
      this.highlightInstances,
      this.hidePassLabel,
      this.isManual,
      this.isValidByDefault,
      this.reversePassFail,
      this.evaluationMethod,
      this.instanceLabelGenerator,
      this.criteria.map((criteria) => criteria.copy()),
      this.instanceLabelOverride,
      this.missingInstancesMessage,
      this.results,
      this.optionDescriptionOverrideMap
    );
  }

  toNestedRequest(
    checklistOverrides: ChecklistOptionOverrides,
    tagsToIgnore: string[]
  ): any {
    var options = this.options;

    if (checklistOverrides) {
      for (var optionKey in options) {
        var key = `${this.uid}-${optionKey}`;
        if (checklistOverrides[key] !== undefined) {
          options[optionKey] = checklistOverrides[key];
        }
      }
    }

    // need to remove null values from options
    const keysToDelete: string[] = [];
    for (var optionKey in options) {
      if (options[optionKey] === null) {
        keysToDelete.push(optionKey);
      }
    }

    for (var keyToDelete of keysToDelete) {
      delete options[keyToDelete];
    }

    const criteriaToInclude = this.criteria.filter((criteria) => {
      if (criteria.tag != undefined && tagsToIgnore.includes(criteria.tag)) {
        return false;
      }

      return true;
    });

    return {
      id: this.id,
      uid: this.uid,
      level: "document",
      options: {
        ...options,
        inferSubtypes: true,
        // reversePassFail would go here
        reversePassFail: this.reversePassFail ?? false,
      },
      infer: true,
      config: {
        text: this.text,
        acceptWordList: this.acceptWordList,
        acceptParagraphPoints: this.acceptParagraphPoints,
        manualInstanceReview: this.manualInstanceReview,
        isMechanics: this.isMechanics,
        enableTypeSelection: this.enableTypeSelection,
        preserveInstanceFormatting: this.preserveInstanceFormatting,
        useWordsToIgnore: this.useWordsToIgnore,
        isVisible: this.isVisible,
        isTopLevelCriteria: this.isTopLevelCriteria,
        shouldShowInstances: this.shouldShowInstances,
        shouldShowToStudents: this.shouldShowToStudents,
        shouldHideExtraInstances: this.shouldHideExtraInstances,
        expandByDefault: this.expandByDefault,
        forceOpen: this.forceOpen,
        highlightInstances: this.highlightInstances,
        hidePassLabel: this.hidePassLabel,
        isManual: this.isManual,
        isValidByDefault: this.isValidByDefault,
        evaluationMethod: this.evaluationMethod,
        instanceLabelOverride: this.instanceLabelOverride,
        missingInstancesMessage: this.missingInstancesMessage,
        optionDescriptionOverrideMap: this.optionDescriptionOverrideMap,
      },
      criteria: criteriaToInclude.map((criteria) =>
        criteria.toNestedRequest(checklistOverrides, tagsToIgnore)
      ),
    };
  }

  flatten(): EvaluationCriteria[] {
    var allCriteria: EvaluationCriteria[] = [this];

    for (var criteria of this.criteria) {
      allCriteria.push(...criteria.flatten());
    }

    return allCriteria;
  }
}
