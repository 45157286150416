export const useDoesRelevantInstanceHaveActiveSubtype = ({
  relevantInstance,
  documentId,
  activeSubTypesOverride,
}: {
  relevantInstance: EvaluationRelevantInstance;
  documentId: string;
  activeSubTypesOverride?: string[];
}) => {
  const documentActiveTypeStore = useDocumentActiveTypeStore(documentId);
  const { activeSubTypes } = storeToRefs(documentActiveTypeStore);

  return computed(() => {
    let hasActiveSubType = false;
    let hasNoSubTypes = true;

    const subTypeOverrides = activeSubTypesOverride || activeSubTypes.value;

    if (subTypeOverrides.length == 0) return 0;

    for (let relevantInstanceToken of relevantInstance.tokens) {
      const tokenStore = useDocumentTokenIdPositionMapStore(
        documentId
      ).tokenStoreFromId(relevantInstanceToken.id);
      const { token: tokenRef } = storeToRefs(tokenStore);

      if (tokenRef.value == undefined) continue;

      const subTypes = tokenRef.value.indicators?.subTypes || [];
      if (subTypes.length > 0) {
        hasNoSubTypes = false;
        if (
          subTypeOverrides.some((activeSubType) =>
            subTypes.includes(activeSubType)
          )
        ) {
          hasActiveSubType = true;
        }
      }
    }

    if (hasActiveSubType) return 1; // Matches activeSubType context
    if (hasNoSubTypes) return 0; // No subTypes
    return -1; // SubTypes that are not in the active context
  });
};
