import { DocumentSubmissionType } from "#imports";
import { Assignment } from "~/classes/models/assignments/assignment.model";
import { Classroom } from "~/classes/models/classrooms/classroom.model";
import { Student } from "~/classes/models/students/student.model";

export const useCreateDocumentParams = ({
  classroom,
  assignment,
  student,
  checklist,
  checklists,
  documentSubmissionType,
  attachChecklist = true,
}: {
  classroom?: Classroom;
  assignment?: Assignment;
  student?: Student;
  checklist?: Checklist;
  checklists: Checklists;
  documentSubmissionType: DocumentSubmissionType;
  attachChecklist?: boolean;
}) => {
  const documentParams = {} as any;
  const uid = useCurrentUID();

  documentParams.userId = uid;

  if (classroom) {
    documentParams.classroom = {
      id: classroom.id,
      name: classroom.name,
    };
  }

  if (assignment) {
    documentParams.assignment = {
      id: assignment.id,
      name: assignment.name,
    };

    if (assignment.checklistId && attachChecklist) {
      const checklistPath =
        checklists?.find((checklist) => checklist.id == assignment.checklistId)
          ?.path ?? "/checklists";

      console.log(checklistPath);

      if (checklistPath) {
        documentParams.checklist = {
          id: assignment.checklistId,
          path: checklistPath,
        };
      }
    }

    if (assignment.classroomId) {
      documentParams.classroom = {
        id: assignment.classroomId,
        name: assignment.classroomName ?? "",
      };
    }
  }

  if (checklist && attachChecklist) {
    documentParams.checklist = {
      id: checklist.id,
      path: checklist.path,
    };
  }

  if (student) {
    documentParams.student = {
      id: student.id,
      name: student.name,
    };

    documentParams.userId = student.userId;
  }

  documentParams.allowFinalDraftGradedState =
    assignment?.allowFinalDraftGradedState != undefined
      ? assignment?.allowFinalDraftGradedState
      : classroom?.allowFinalDraftGradedState;

  documentParams.documentSubmissionType = documentSubmissionType;

  return documentParams;
};
