export const useChooseRelevantInstance = async (
  documentId: string,
  allRelevantInstances: EvaluationRelevantInstance[],
  chosenInstance: EvaluationRelevantInstance,
  activeTypes: string[],
  activeSubTypes: string[]
) => {
  const tokens = [] as ParagraphTokens;

  for (const relevantInstanceToken of chosenInstance.tokens) {
    const token = useDocumentTokenIdPositionMapStore(
      documentId
    ).tokenStoreFromId(relevantInstanceToken.id).token;

    if (token == undefined) {
      continue;
    }

    token.indicators.types = activeTypes;
    token.indicators.subTypes = activeSubTypes;

    tokens.push(token);
  }

  const nonChosenInstances = allRelevantInstances.filter(
    (instance) => instance.uid !== chosenInstance.uid
  );

  for (const nonChosenInstance of nonChosenInstances) {
    for (const token of nonChosenInstance.tokens) {
      const tokenStore = useDocumentTokenIdPositionMapStore(
        documentId
      ).tokenStoreFromId(token.id);

      if (tokenStore.token == undefined) {
        continue;
      }

      tokenStore.token.indicators.types =
        tokenStore.token.indicators.types.filter(
          (type) => !activeTypes.includes(type)
        );

      tokenStore.token.indicators.subTypes =
        tokenStore.token.indicators.subTypes.filter(
          (subType) => !activeSubTypes.includes(subType)
        );
    }
  }

  await useDocumentParagraphsStore(documentId).saveTokens(tokens);
};
