<script lang="ts" setup>
import { DocumentViewMode } from "~/types/enums/DocumentViewMode.enum";

const props = defineProps({
  submittedDocument: {
    type: Object as PropType<SubmittedDocument>,
  },
  mode: {
    type: String as PropType<DocumentViewMode>,
    required: true,
  },
  showViewButton: {
    type: Boolean,
    default: true,
  },
  filled: {
    type: Boolean,
    default: true,
  },
});

const isLate = computed(() => {
  return props.submittedDocument?.isLate;
});

const isDocumentReady = computed(() => {
  if (!props.submittedDocument) return false;

  return useIsDocumentReadyToGrade(props.submittedDocument);
});

const showGradedState = computed(() => {
  if (!props.submittedDocument) return false;

  return useShowDocumentGradedState(props.submittedDocument);
});

const showGrade = computed(() => {
  return (
    props.submittedDocument?.state == DocumentState.graded &&
    (props.submittedDocument.documentSubmissionType ??
      DocumentSubmissionType.finalDraft) == DocumentSubmissionType.finalDraft
  );
});
</script>

<template>
  <NuxtLink
    v-if="submittedDocument"
    class="flex flex-row justify-end items-center cursor-pointer w-full"
    target="_blank"
    :to="useGenerateDocumentPath(submittedDocument, mode)"
  >
    <span v-if="isLate" class="text-danger-default mr-4"> late </span>
    <NuxtLink
      v-if="
        showViewButton &&
        isDocumentReady &&
        useCanGradeDocument(submittedDocument, mode) &&
        useGenerateDocumentPath(submittedDocument, mode)
      "
      :to="useGenerateDocumentPath(submittedDocument, mode)"
      target="_blank"
      class="mr-4"
    >
      <BaseTextButton>View</BaseTextButton>
    </NuxtLink>

    <DocumentGradedStatePill
      v-if="showGradedState && submittedDocument?.documentGradedState"
      class="!w-[150px] mr-1"
      :document-graded-state="submittedDocument.documentGradedState"
    />
    <span
      v-if="
        showGradedState != true &&
        submittedDocument.state == DocumentState.graded &&
        showGrade == true &&
        submittedDocument.earnedPoints != undefined &&
        submittedDocument.maxPoints != undefined
      "
      class="h-[42px] w-[150px] p-2 border-2 bg-light-default rounded shrink-0 mr-1"
    >
      {{ submittedDocument.earnedPoints }} /
      {{ submittedDocument.maxPoints }} points
    </span>
    <DocumentStatePill
      :state="submittedDocument?.state ?? DocumentState.notImported"
      :filled="filled"
    />
  </NuxtLink>
</template>

<style scoped></style>
