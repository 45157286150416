export const useDocumentActiveTypes = (
  documentId: string,
  revisionId: string
) =>
  definePiniaStore(
    `/document/${documentId}/revision/${revisionId}/active/types`,
    () => {
      const activeTypes = ref<string[]>([]);
      const activeSubTypes = ref<string[]>([]);
      const activeCriteria = ref<ChecklistCriteria | undefined>(undefined);

      const setActiveTypes = (types: string[]) => {
        activeTypes.value = types;
      };
      const setActiveSubTypes = (checklistCriteria: ChecklistCriteria) => {
        if (checklistCriteria.activeSubTypes) {
          activeSubTypes.value = checklistCriteria.activeSubTypes;
          activeCriteria.value = checklistCriteria;
        }
      };

      const clearAll = () => {
        activeTypes.value = [];
        activeSubTypes.value = [];
        activeCriteria.value = undefined;
      };

      const hasActiveTypes = computed(() => {
        return activeTypes.value.length > 0;
      });

      const isAnyTypeActive = computed(() => {
        return (types: string[]) => {
          return types.some((type) => activeTypes.value.includes(type));
        };
      });

      const isAnySubTypeActive = computed(() => {
        return (subTypes: string[]) => {
          return subTypes.some((subType) =>
            activeSubTypes.value.includes(subType)
          );
        };
      });

      return {
        hasActiveTypes,
        activeTypes,
        activeSubTypes,
        activeCriteria,
        isAnyTypeActive,
        isAnySubTypeActive,
        setActiveTypes,
        setActiveSubTypes,
        clearAll,
      };
    }
  );
