import { Assignment } from "classes/models/assignments/assignment.model";
import { AssignmentsService } from "classes/models/assignments/assignments.service";
import { Student } from "classes/models/students/student.model";
import { StudentsService } from "classes/models/students/students.service";
import { collection, query, Query, where } from "firebase/firestore";

// @ts-ignore
import { collectionData } from "rxfire/firestore";
import { ClassSessions } from "~/classes/models/class-sessions/class-session.model";
import { ClassSessionsService } from "~/classes/models/class-sessions/class-sessions.service";

import { catchError, Subscription } from "rxjs";
import { Classroom } from "~/classes/models/classrooms/classroom.model";
import { StudentNotes } from "~/classes/models/students/student-notes.model";

export const useClassroomData = (userId: string, classroomId: string) =>
  defineStore(`/users/${userId}/classrooms/${classroomId}/data`, () => {
    const classSessions = ref<ClassSessions>([]);
    const classroom = ref<Classroom | undefined>();
    const assignments = ref<Assignment[]>([]);
    const students = ref<Student[]>([]);
    const studentNotes = ref<StudentNotes>([]);

    const isInitialized = ref(false);
    const isClassSessionsInitialized = ref(false);
    const isAssignmentsInitialized = ref(false);
    const isStudentsInitialized = ref(false);
    const isStudentNotesInitialized = ref(false);
    const classSessionsSubscription = ref<Subscription | undefined>();
    const assignmentsSubscription = ref<Subscription | undefined>();
    const studentsSubscription = ref<Subscription | undefined>();
    const studentNotesSubscription = ref<Subscription | undefined>();

    const reset = () => {
      isInitialized.value = false;
      isClassSessionsInitialized.value = false;
      isAssignmentsInitialized.value = false;
      isStudentsInitialized.value = false;
      classroom.value = undefined;
      classSessions.value = [];
      assignments.value = [];
      students.value = [];

      classSessionsSubscription.value?.unsubscribe();
      assignmentsSubscription.value?.unsubscribe();
      studentsSubscription.value?.unsubscribe();
      studentNotesSubscription.value?.unsubscribe();
    };

    const initialize = (defaultClassroom: Classroom) => {
      if (isInitialized.value) return;

      classroom.value = defaultClassroom;

      classSessionsSubscription.value?.unsubscribe();

      classSessionsSubscription.value =
        ClassSessionsService.streamClassSessions({
          classroomId,
          userId,
          includePrivateNotes: true,
        }).subscribe((data) => {
          isClassSessionsInitialized.value = true;
          classSessions.value = data.filter((session) => !session.isArchived);
        });

      assignmentsSubscription.value?.unsubscribe();
      assignmentsSubscription.value = AssignmentsService.streamAssignments(
        classroomId,
        userId
      )
        .pipe(
          catchError((error: any) => {
            return [];
          })
        )
        .subscribe((data) => {
          assignments.value = data;
          isAssignmentsInitialized.value = true;
          for (const assignment of assignments.value) {
            initializeAssignment(assignment);
          }
        });

      studentsSubscription.value?.unsubscribe();
      studentsSubscription.value = StudentsService.streamClassroomStudents(
        classroomId,
        userId
      ).subscribe((data) => {
        students.value = data;

        isStudentsInitialized.value = true;
      });

      studentNotesSubscription.value?.unsubscribe();
      studentNotesSubscription.value =
        StudentsService.streamClassroomStudentNotes({
          classroomId,
          userId,
        }).subscribe((data) => {
          studentNotes.value = data;
          isStudentNotesInitialized.value = true;
        });

      isInitialized.value = true;
    };

    const initializeAssignment = (assignment: Assignment) => {
      if (assignment.id === undefined) return;

      const assignmentDataStore = useAssignmentDataStore(
        assignment.classroomId,
        assignment.id
      );
      assignmentDataStore.initialize();
    };

    const numUngradedDocuments = computed(() => {
      let numDocuments = 0;

      for (const assignment of assignments.value) {
        if (!assignment.id) continue;

        const assignmentDataStore = useAssignmentDataStore(
          assignment.classroomId,
          assignment.id
        );
        const { ungradedDocuments } = storeToRefs(assignmentDataStore);
        numDocuments += ungradedDocuments.value.length;
      }

      return numDocuments;
    });

    const numGradedDocuments = computed(() => {
      let numDocuments = 0;

      for (const assignment of assignments.value) {
        if (!assignment.id) continue;

        const assignmentDataStore = useAssignmentDataStore(
          assignment.classroomId,
          assignment.id
        );
        const { gradedDocuments } = storeToRefs(assignmentDataStore);
        numDocuments += gradedDocuments.value.length;
      }

      return numDocuments;
    });

    // Add entities

    const addAssignment = async () => {
      const uid = useCurrentUID();

      if (!classroom.value) throw new Error("Missing classroom");

      if (!uid) throw new Error("Missing uid");

      const assignment = Assignment.default(
        uid,
        classroomId,
        classroom.value.name!
      );

      await assignment.save();

      return assignment;
    };

    const addStudent = async () => {
      const uid = useCurrentUID();
      if (!classroom.value) throw new Error("Missing classroom");

      if (!uid) throw new Error("Missing uid");

      const student = Student.default(uid, classroomId, classroom.value.name!);

      await student.save();

      return student;
    };

    // Assignment data computed values
    const assignmentUngradedDocuments = computed(() => {
      return (assignmentId: string) => {
        const assignmentDataStore = useAssignmentDataStore(
          classroomId,
          assignmentId
        );
        const { ungradedDocuments } = storeToRefs(assignmentDataStore);

        return ungradedDocuments.value;
      };
    });

    const assignmentGradedDocuments = computed(() => {
      return (assignmentId: string) => {
        const assignmentDataStore = useAssignmentDataStore(
          classroomId,
          assignmentId
        );
        const { gradedDocuments } = storeToRefs(assignmentDataStore);

        return gradedDocuments.value;
      };
    });

    const streamClassroomGradedDocument = () => {
      const db = useFirestore();
      const uid = useCurrentUID();

      if (!uid) {
        throw new Error("Missing uid");
      }

      const documentsRef = collection(db, "/documents");
      const documentsQuery = query(
        documentsRef,
        where("classroomId", "==", classroomId),
        where("userId", "==", uid),
        where("state", "==", DocumentState.graded)
      ) as Query<SubmittedDocument>;

      return collectionData<SubmittedDocument>(documentsQuery, {
        idField: "id",
      });
    };

    const streamStudentDocuments = (studentId: string) => {
      const db = useFirestore();

      const documentsRef = collection(db, "/documents");
      const documentsQuery = query(
        documentsRef,
        where("classroomId", "==", classroomId),
        where("studentId", "==", studentId)
      ) as Query<SubmittedDocument>;

      return collectionData<SubmittedDocument>(documentsQuery, {
        idField: "id",
      });
    };

    const studentsNote = computed(() => {
      return (studentId: string) => {
        return studentNotes.value.find((note) => note.studentId === studentId);
      };
    });

    return {
      classroom,
      classSessions,
      assignments,
      students,
      studentNotes,
      studentsNote,
      isInitialized,
      isClassSessionsInitialized,
      isAssignmentsInitialized,
      isStudentsInitialized,
      isStudentNotesInitialized,
      numUngradedDocuments,
      numGradedDocuments,
      initialize,

      addAssignment,
      addStudent,

      assignmentUngradedDocuments,
      assignmentGradedDocuments,
      streamClassroomGradedDocument,
      streamStudentDocuments,

      reset,
    };
  });
